import React from 'react';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import cn from 'classnames';
import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModal/BookDemoButton';
import GetStartedButton from '@/dataroom/ui/components/Landing/common/GetStartedButton';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import baseStyles from '@/dataroom/ui/components/Landing/dealvdr/tenantDealvdrStyles.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './startActions.scss';

interface IProps {
  dividerClassName?: string,
  isGetStartedButton?: boolean,
}

const StartActions = ({ dividerClassName, isGetStartedButton }: IProps) => (
  <div className={ styles.actionsWrp }>
    <BookDemoButton className={ cn(baseStyles.actionButton, styles.bookDemoButton) } />
    <span className={ cn(styles.buttonsDivider, dividerClassName) }>or</span>
    { isGetStartedButton ? (
      <GetStartedButton className={ cn(baseStyles.actionButton, styles.getStartedButton, spacesStyles.mrn) } />)
      : (
        <NextNavLink to="/get-a-quote">
          <Button
            variant={ variantTypes.action }
            className={ cn(baseStyles.actionButton, styles.getStartedButton, spacesStyles.mrn) }
            title="Get a Quote"
            dataTest="learnMoreGetQuoteButton"
          />
        </NextNavLink>
      ) }
  </div>
);

export default StartActions;
